<template>
  <CalendarEventSlotsFormModal
    v-if="hasSlots"
    :visible="visible"
    :target="member"
    target-type="member"
    @update:visible="$emit('update:visible', $event)"
  />
  <CalendarEventFormModal
    v-else
    :visible="visible"
    :event="event"
    :clear-event-data="clearEventData"
    @add-event="addEvent"
    @update:visible="$emit('update:visible', $event)"
    @show="handleBeforeShow"
  />
</template>

<script>
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
// import { EVENTS_ACTIONS } from '@/views/apps/events/constants/events-store-constants';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import BlankCalendarEvent from '@/views/apps/calendar/constants/BlankCalendarEvent';
import { DateTime } from 'luxon';
import moment from 'moment';
// import CalendarEventFormModal from '@/views/apps/calendar/components/modals/CalendarEventFormModal.vue';
// import CalendarEventSlotsFormModal from '@/views/apps/calendar/components/modals/CalendarEventSlotsFormModal.vue';

export default {
  name: 'RequestMeetingModal',

  components: { 
    CalendarEventFormModal: () => import ('@/views/apps/calendar/components/modals/CalendarEventFormModal.vue'  /* webpackChunkName: "CalendarEventFormModal" */),
    CalendarEventSlotsFormModal: () => import ('@/views/apps/calendar/components/modals/CalendarEventSlotsFormModal.vue'  /* webpackChunkName: "CalendarEventSlotsFormModal" */),
  },

  mixins: [ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
    withShared: Boolean,
    member: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      event: JSON.parse(JSON.stringify(BlankCalendarEvent)),
    };
  },
  computed: {
    hasSlots() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps].meetingsSlots;
    },
  },
  methods: {
    clearEventData() {
      this.event = JSON.parse(JSON.stringify(BlankCalendarEvent));
    },
    handleBeforeShow() {
      this.clearEventData();
      this.event.start = DateTime.local();
      this.event.start = moment(new Date(this.event.start.ts));

      this.event.end = DateTime.local().plus({ hours: 1 });
      this.event.end = moment(new Date(this.event.end.ts));

      if (this.member) {
        this.event.extendedProps.guests.push(this.member);
      }
    },
    async addEvent(eventData) {
      try {
        const event = eventData;
        if (event.start[event.start.length - 1] === 'Z') {
          event.start = event.start.substring(0, event.start.length - 1);
        }
        if (event.end[event.end.length - 1] === 'Z') {
          event.end = event.end.substring(0, event.end.length - 1);
        }
        const locale = this.$store.getters.currentLocale;
        await this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: 'createMeeting',
            requestConfig: {
              name: { [locale]: event.title },
              description: { [locale]: event.extendedProps.description },
              locations: event.extendedProps.location ? [event.extendedProps.location] : [],
              allDay: event.extendedProps.allDay,
              withShared: this.withShared,
              showCreated: true,
              timezone: event.extendedProps.timezone,
              modality: event.extendedProps.modality === 'online' ? 1 : 0,
              eventType:
                this.withShared === true ? 27452 : eventData.extendedProps?.calendar === 'Meeting' ? 27413 : null,
              startDate: new Date(event.start),
              endDate: event.end ? new Date(event.end) : null,
              invitedPeople: (event.extendedProps.guests || []).map(({ userKey }) => userKey),
              collectiveKey: this.$store.getters.currentCollective.key,
              isMeeting: 1,
            },
          },
        });
        this.$emit('add-event', eventData);
        this.notifySuccess(this.$t('calendar.messages.event-added.success'));
        this.$emit('update:visible', false);
      } catch {
        this.notifyError(this.$t('calendar.messages.event-added.error'));
      }
    },
  },
};
</script>
