<template>
  <div>
    <b-card>
      <div class="w-100 my-1 mb-2 float-left pl-1">
        <div class="d-flex align-items-center float-left">
          <!-- <a class="mr-50" @click="$router.go(-1)">
            <feather-icon icon="ChevronLeftIcon" size="20" />
          </a> -->
          <h3 class="m-0 font-weight-600">
            {{ $t("grants.title") }}
          </h3>
        </div>
        <b-button
          v-if="canAdd"
          v-b-modal.modal-create-grant
          class="float-right mr-1"
          show-add
          variant="outline-primary"
        >
          {{ $t("grants.create") }}
        </b-button>
      </div>

      <!-- Loading -->
      <div v-if="isLoading">
        <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
      </div>

      <!-- Container with data -->
      <div v-else-if="items.length > 0" class="cards-container">
        <div>
          <!-- Items Loop -->
          <b-card
            v-for="(item, index) in items"
            :key="index"
            no-body
            class="float-left mx-1 mb-2 p-0 card-standard"
          >
            <b-link :to="{ name: 'grant', params: { id: item.key } }">
              <!-- Image -->
              <b-card-body class="pb-2">
                <!-- <div class="item-img text-center">
                  <b-img
                    :alt="`${item.name}`"
                    fluid
                    :src="getImageSrc(item.bgURL) || productPlaceholder"
                    class="card-image-medium"
                  />
                </div> -->
                <!-- Product Details -->
                <h4 class="mt-2 text-ellipsis-lines-2">
                  {{ item.name }}
                </h4>
                <b-card-text v-if="item.budget && item.budget > 0">
                  <h5 class="font-weight-bold">
                    {{ $t("form-create-item.budget") }}: {{ item.budget }} €
                    <!-- {{ item.currency }} -->
                  </h5>
                </b-card-text>
                <b-card-text v-else>
                  <h5 class="font-weight-bold">
                    0 €
                    <!-- {{ item.currency }} -->
                  </h5>
                </b-card-text>
                <!-- <span
                  v-if="item.headline"
                  class="min-height-3 html-text-ellipsis-2 text-secondary"
                  v-html="item.headline"
                /> -->
                <span
                  v-if="item.description"
                  class="min-height-3 html-text-ellipsis-2 text-secondary"
                  v-html="item.description"
                />
                <div v-else class="min-height-3" />
              </b-card-body>
              <!-- Product Actions -->
              <!-- <div v-if="false" class="text-center w-100 mt-1">
                <b-button
                  v-if="hasMeetingsSlotsAddOn || hasMeetingAddOn"
                  variant="light"
                  tag="a"
                  class="btn-wishlist w-50 rounded-0 px-1"
                  @click="handleCalendarMeetingOpen()"
                >
                  <feather-icon icon="CalendarIcon" class="mr-25" />
                  Crear meeting
                </b-button>
                <b-button
                  v-if="hasChatIndividualAddOn"
                  variant="primary"
                  tag="a"
                  class="btn-cart w-50 rounded-0 px-1"
                  @click="handleSidebarChange()"
                >
                  <feather-icon icon="MessageSquareIcon" class="mr-25" />
                  Chatear
                </b-button>
              </div> -->
            </b-link>
          </b-card>
        </div>

        <!-- Load More items -->
        <div
          v-if="items.length < itemsData.meta.total"
          class="w-100 float-left"
        >
          <b-button
            v-if="!isLoadingNextPage"
            class="center-x my-3"
            variant="outline-primary"
            @click="handleLoadOfNewItems"
          >
            {{ $t("action.load-more") }}
          </b-button>
          <div v-else class="w-100">
            <b-spinner
              class="center-x my-3"
              small
              variant="primary"
              type="grow"
              label="Loading..."
            />
          </div>
        </div>
      </div>

      <!-- Container without data: Placeholder -->
      <b-row v-else class="horizontal-placeholder">
        <b-col cols="12">
          <img :src="grantsPlaceholder" />
        </b-col>
        <b-col cols="12">
          <p class="text-primary">
            {{ $t("available.message", { itemName: "items" }) }}
          </p>
        </b-col>
      </b-row>
    </b-card>

    <!-- Create Product -->
    <b-modal
      id="modal-create-grant"
      :title="$t('grants.modal-create-title')"
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @ok="handleCreateItem"
    >
      <b-form>
        <b-form-group>
          <label for="name">{{ $t("form-create-item.title") }}:</label>
          <b-form-input
            id="name"
            v-model="grantInput.name"
            type="text"
            :state="formError"
          />
        </b-form-group>
        <b-form-group>
          <label for="price"> {{ $t("form-create-item.budget") }}:</label>
          <b-form-input id="price" v-model="grantInput.budget" type="number" />
        </b-form-group>
        <b-form-group>
          <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
          <quill-editor v-model="grantInput.description" />
        </b-form-group>

        <!-- <p class="mt-3">
          {{ $t('form-create-item.image') }}:
        </p>
        <div class="border rounded p-2">
          <p class="mb-50">
            {{ $t('form-create-item.image-select') }}
          </p>
          <b-media no-body vertical-align="center" class="d-flex align-items-center flex-column flex-md-row">
            <b-media-aside>
              <b-img
                ref="refPreviewEl"
                :src="imageSrc"
                height="110"
                width="170"
                class="rounded mr-2 mb-1 mb-md-0"
                style="background: #c0c0c0;"
              />
            </b-media-aside>
            <b-media-body>
              <div class="d-inline-block d-flex align-items-center pl-2">
                <b-form-file
                  class="pointer"
                  v-model="itemImage"
                  :accept="FileTypes"
                  :placeholder="$t('form-create-item.image-placeholder')"
                  :browse-text="$t('form-create-item.image-button')"
                />
              </div>
            </b-media-body>
          </b-media>
        </div> -->
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import grantsPlaceholder from "@/assets/images/placeholders/light/grant.svg";
import ProductPlaceholder from "@/assets/images/placeholders/light/placeholder-dark.jpeg";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import { getImageResource } from "@/@core/utils/image-utils";
import { quillEditor } from "vue-quill-editor";
// import FileTypes from '@core/constants/FileTypes';

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardText,
  BButton,
  BSpinner,
  BLink,
  BModal,
  BForm,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BFormFile,
} from "bootstrap-vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import RequestMeetingModal from "../../member/modals/RequestMeetingModal.vue";
import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  name: "GrantsList",
  components: {
    quillEditor,
  },
  data() {
    return {
      isLoading: false,
      isLoadingNextPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      grantInput: {},
      formError: null,
      itemImage: null,
      imageSrc: null,
    };
  },
  computed: {
    itemsData() {
      return this.$store.getters.grants;
    },
    items() {
      return this.itemsData.unpaginated;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return (
        checkPermissions(
          "create",
          "grant",
          this.loggedMemberRoles,
          this.collective
        ) || this.isStaff
      );
    },
    grantsPlaceholder() {
      return grantsPlaceholder;
    },
  },
  watch: {
    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    this.updateBreadcrumbs();
    await this.fetchData();
    this.isLoading = false;
  },
  methods: {
    updateBreadcrumbs() {
      this.$store.commit("app/SET_BREADCRUMBS", null);
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async fetchData() {
      await this.$store.dispatch("getItems", {
        itemType: "grants",
        page: this.lastLoadedPage,
        requestConfig: {
          count: 16,
          orderByDate: -1,
        },
      });
      this.isLoading = false;
    },

    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData();
        this.isLoadingNextPage = false;
      }
    },

    async handleCreateItem(bvModalEvt) {
      // Name is required
      if (!this.grantInput.name) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        await this.$store.dispatch("createItem", {
          item: {
            itemType: "grants",
            requestConfig: {
              name: this.grantInput.name,
              description: this.grantInput.description,
              budget: this.grantInput.budget,
            },
          },
          file: this.itemImage,
        });
        this.grantInput = {};
        this.formError = null;
        this.itemImage = null;
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t("error-message.general-error"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
<style scoped>
/* TODO: fix responsive with bootstrap, better than media queries!! */
@media only screen and (max-width: 600px) {
  .cards-container {
    margin-left: -2rem;
  }
}
</style>
