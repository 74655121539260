const CalendarMeetingType = {
  Meeting: 'Meeting',
  Matchmaking: 'Matchmaking',
  Room: 'Room',
  PublicEvent: 'PublicEvent',
  PrivateEvent: 'PrivateEvent',
  Canceled: 'Canceled',
};

export default CalendarMeetingType;
