import CalendarMeetingType from '@/views/apps/calendar/constants/CalendarMeetingType';

const BlankCalendarEvent = Object.freeze({
  title: '',
  start: '',
  end: '',
  allDay: false,
  url: '',
  extendedProps: {
    allDay: false,
    calendar: CalendarMeetingType.Meeting,
    location: null,
    description: '',
    guests: [],
  },
});

export default BlankCalendarEvent;
